<template>
  <q-footer
    class="footerNav"
    elevated
  >
    <div
      class="footerToolbar q-pt-xl"
    >
      <div
        class="row justify-between q-mb-xl"
      >
        <div class="column justify-evenly q-gutter-md">
          <a href="https://www.wirecast.io/">
            <img
              src="@/assets/logo/wc-full-logo-white.svg"
              class="wirecastLogo"
            >
          </a>
          <p>
            {{ t('Footer.WcSlogan') }}
          </p>
          <a href="https://www.telestream.net/telestream-home.htm">
            <img
              src="@/assets/logo/telestream-logo-white.png"
              class="tsLogo"
            >
          </a>
        </div>
        <div class="column q-gutter-md">
          <a href="https://www.wirecast.io/">
            {{ t('Footer.Overview') }}
          </a>
          <a href="https://www.wirecast.io/product/">
            {{ t('Footer.Product') }}
          </a>
          <a href="https://www.wirecast.io/customers/">
            {{ t('Footer.Customers') }}
          </a>
          <a href="https://www.wirecast.io/pricing/">
            {{ t('Footer.Pricing') }}
          </a>
          <a href="https://www.wirecast.io/resources/">
            {{ t('Footer.Resources') }}
          </a>
        </div>
        <div class="column q-gutter-md">
          <a href="https://telestreamblog.telestream.net/category/product/wirecast/" target="_blank">
            {{ t('Footer.Blogs') }}
            <q-icon
              name="ion-open"
            />
          </a>
          <a href="https://www.telestream.net/telestream-support/sml-claim.htm" target="_blank">
            {{ t('Footer.CopyrightClaim') }}
            <q-icon
              name="ion-open"
            />
          </a>
          <a href="https://www.telestream.net/company/contact-desktop-sales.htm" target="_blank">
            {{ t('Footer.Support') }}
            <q-icon
              name="ion-open"
            />
          </a>
        </div>
        <ul>
          <li>
            <div class="row q-gutter-lg justify-evenly">
              <a href="https://www.facebook.com/TelestreamWirecast">
                <q-icon
                  class="facebookLogo"
                  name="ion-logo-facebook"
                  size="sm"
                  round
                />
              </a>
              <a href="https://twitter.com/Wirecast">
                <q-icon
                  class="twitterLogo"
                  name="ion-logo-twitter"
                  size="sm"
                  round
                />
              </a>
              <a href="https://www.youtube.com/user/WirecastTube">
                <q-icon
                  class="youtubeLogo"
                  name="ion-logo-youtube"
                  size="sm"
                  round
                />
              </a>
              <a href="https://www.linkedin.com/company/telestream/">
                <q-icon
                  class="linkedinLogo"
                  name="ion-logo-linkedin"
                  size="sm"
                  round
                />
              </a>
            </div>
          </li>
        </ul>
      </div>
      <q-separator
        class="footerSeparator q-mt-lg"
      />
      <div
        class="row justify-between q-pt-xl q-pb-lg"
      >
        <p>
          {{ t('Footer.CopyrightNotice') }}
        </p>
        <div
          class="row q-gutter-xl"
        >
          <a href="https://www.wirecast.io/privacy-policy/">
            <p>
              {{ t('Footer.PrivacyPolicy') }}
            </p>
          </a>
          <a href="https://www.wirecast.io/terms-of-service/">
            <p>
              {{ t('Footer.TermsOfService') }}
            </p>
          </a>
        </div>
      </div>
    </div>
  </q-footer>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

// Gloabl
const { t } = useI18n({ useScope: 'global' })
</script>

<style lang="scss" scoped>
.footerNav {
  background-color: var(--bg-accent);
  padding-left: 20px;
  padding-right: 20px;
  bottom: initial;
}

.footerToolbar {
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
  color: white;
  font-size: 1rem;
}

.footerToolbar a {
  color: white;
  text-decoration: none;
}

.footerToolbar a:hover:not(.q-btn) {
  text-decoration: underline;
}

.wirecastLogo {
  width: 200px;
}

.tsLogo {
  width: 119px;
}

.facebookLogo {
  background: white;
  color: #1877F2;
  width: 17px;
  height: 18px;
}

.twitterLogo {
  color: #1DA1F2;
  width: 17px;
  height: 18px;
}

.youtubeLogo {
  background: white;
  color: #CD201F;
  width: 17px;
  height: 18px;
}

.linkedinLogo {
  background: white;
  color: #0A66C2;
  width: 17px;
  height: 18px;
  border-radius: 3px;
}

.linksSection {
  padding-left: 0px;
}

.sectionHeader {
  color: $grey-12;
  font-family: InterBold;
  font-size: 0.8rem;
}

.footerToolbar ul {
  list-style-type: none;
}

.footerToolbar li {
  padding-top: 3px;
  padding-bottom: 3px;
}

.footerToolbar ul ul {
  padding-left: 20px;
}

.footerSeparator {
  background: $grey-7;
}

@media (max-width: 1024px) {
  .footerToolbar {
    width: 100%;
  }
}
</style>

<style lang="sass" scoped>
.column > div
  padding: 0px 15px
.column + .column
  margin-top: 1rem
</style>
