const namespaced = true

const state = {
  analyticsObj: {}
}

const getters = {
  getAnalytics (state) {
    return state.analyticsObj
  }
}

const mutations = {
  setAnalytics (state, value) {
    state.analyticsObj = value
  }
}

export default {
  state,
  getters,
  mutations,
  namespaced
}
