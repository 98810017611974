import './styles/quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Dark from 'quasar/src/plugins/Dark.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    dark: false
  },
  plugins: {
    Notify,
    Dialog,
    Loading,
    Dark
  }
}
