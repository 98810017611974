import useApi from '@/Global/composables/api.js'

const api = useApi
const namespaced = true
const state = {
  userId: '',
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  profilePicture: '',
  country: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  emailConsent: false
}

const getters = {
  getUserId (state) {
    return state.userId
  },
  getName (state) {
    return state.name
  },
  getFirstName (state) {
    return state.firstName
  },
  getLastName (state) {
    return state.lastName
  },
  getInitials (state) {
    return `${state.firstName[0]}${state.lastName[0]}`
  },
  getFullName (state) {
    return `${state.firstName} ${state.lastName}`
  },
  getEmail (state) {
    return state.email
  },
  getProfilePicture (state) {
    return state.profilePicture
  },
  getCountry (state) {
    return state.country
  },
  getAddress (state) {
    return state.address
  },
  getCity (state) {
    return state.city
  },
  getState (state) {
    return state.state
  },
  getZip (state) {
    return state.zip
  },
  getEmailConsent (state) {
    return state.emailConsent
  },
  getBillingAddress (state) {
    return { country: state.country, address: state.address, city: state.city, state: state.state, zip: state.zip }
  },
  getUser (state) {
    return {
      firstName: state.firstName,
      lastName: state.lastName,
      address: state.address,
      city: state.city,
      state: state.state,
      zip: state.zip,
      country: state.country,
      emailMarketingConsentGiven: state.emailConsent
    }
  }
}

const actions = {
  getUserAuth0 ({ commit }, auth0) {
    return new Promise((resolve, reject) => {
      try {
        const user = auth0.user.value
        commit('setName', user.nickname)
        commit('setEmail', user.email)
        commit('setProfilePicture', user.picture)
        auth0.getAccessTokenSilently().then((token) => {
          api.accessToken = token
          resolve(true)
        })
      } catch (error) {
        reject(error)
      }
    })
  },
  getUserProfile ({ commit }) {
    return new Promise((resolve, reject) => {
      const payload = {
        endpoint: 'profile'
      }

      api.billing.v1.get(payload).then((response) => {
        commit('setFirstName', response.firstName)
        commit('setLastName', response.lastName)
        commit('setCountry', response.country)
        commit('setState', response.state)
        commit('setCity', response.city)
        commit('setZip', response.zip)
        commit('setAddress', response.address)
        commit('setEmailConsent', response.emailMarketingConsentGiven)
        resolve(true)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  commitUserInfo ({ commit }, userInfo) {
    commit('setFirstName', userInfo.firstName)
    commit('setLastName', userInfo.lastName)
    commit('setEmail', userInfo.email)
  },

  commitAddressInfo ({ commit }, addressInfo) {
    commit('setCountry', addressInfo.country)
    commit('setAddress', addressInfo.address)
    commit('setCity', addressInfo.city)
    commit('setState', addressInfo.state)
    commit('setZip', addressInfo.zip)
  },

  logout ({ commit }) {
    commit('setFirstName', '')
    commit('setLastName', '')
    commit('setEmail', '')
  }
}

const mutations = {
  setUserId (state, value) {
    state.userId = value
  },
  setName (state, value) {
    state.name = value
  },
  setFirstName (state, value) {
    state.firstName = value
  },
  setLastName (state, value) {
    state.lastName = value
  },
  setEmail (state, value) {
    state.email = value
  },
  setProfilePicture (state, value) {
    state.profilePicture = value
  },
  setCountry (state, value) {
    state.country = value
  },
  setAddress (state, value) {
    state.address = value
  },
  setCity (state, value) {
    state.city = value
  },
  setState (state, value) {
    state.state = value
  },
  setZip (state, value) {
    state.zip = value
  },
  setEmailConsent (state, value) {
    state.emailConsent = value
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced
}
