import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './Global/store'

import { createI18n } from 'vue-i18n'
import activeLocales from '../public/locales/activeLocales.json'

import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { createAuth0 } from '@auth0/auth0-vue'

// Locales Logic

const messages = {}

activeLocales.forEach((locale) => {
  try {
    const file = require(`../public/locales/${locale}.json`)
    console.log(locale)
    if (locale === 'en-US') {
      messages.en = file
    } else {
      messages[locale] = file
      console.log(messages)
    }
  } catch (e) {
    // Ignore file
  }
})

const i18n = createI18n({
  locale: window.navigator.language,
  fallbackLocale: 'en',
  legacy: false,
  messages
})

const analyticsOptions = {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_PUBLIC_KEY || ''
  }
}

// Create App Logic

const app = createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(i18n)

// Routing Logic

app.use(
  createAuth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: process.env.VUE_APP_AUTH0_API_AUDIENCE,
      scope: 'profile email',
      redirect_uri: `${window.location.origin}/authenticate`
    }
  })
)

// Mount Logic

app.mount('#app')
