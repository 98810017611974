<template>
  <div>
    <!-- Sidebar Drawer -->
    <q-drawer
      v-model="leftDrawerOpen"
      side="left"
      bordered
      overlay
    >
      <q-list>
        <q-item
          clickable
          @click="goTo('dashboardView')"
          :class="[isActiveRoute('dashboardView') ? 'active': '']"
        >
          <q-item-section>
            {{ t('Header.Dashboard') }}
          </q-item-section>
        </q-item>
        <q-item
          clickable
          @click="goTo('accountView')"
          :class="[isActiveRoute('informationAndAccess') || isActiveRoute('personalData') ? 'active': '']"
        >
          <q-item-section>
            {{ t('Header.Account') }}
          </q-item-section>
        </q-item>
        <q-item
          clickable
          @click="goTo('invoicesView')"
          :class="[isActiveRoute('invoicesView') ? 'active': '']"
        >
          <q-item-section>
            {{ t('Header.Invoices') }}
          </q-item-section>
        </q-item>
        <q-item
          clickable
          v-if="isAuthenticated && userName !== ''"
          @click="logout"
        >
          <q-item-section>
            {{ t('Header.Logout') }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <!-- Header -->
    <q-header
      class="navHeader bg-card-bg"
      elevated
      reveal
    >
      <q-toolbar class="headerToolbar full-height">
        <!-- Hamburger Menu (visible on mobile) -->
        <q-btn
          flat
          dense
          round
          icon="menu"
          @click="toggleLeftDrawer"
          class="hamburger-btn q-mr-md"
        />

        <!-- Logo -->
        <img class="headerLogo" src="@/assets/logo/wc-logo-white.png">

        <!-- Tabs (only shown on larger screens) -->
        <q-tabs
          v-if="!isMobile && isAuthenticated && !auth0IsLoading && currentPlan !== ''"
          v-model="navTab"
          class="navTabs"
          no-caps
        >
          <q-route-tab
            name="dashboard"
            :label="t('Header.Dashboard')"
            :to="{ name: 'dashboardView' }"
            exact
            replace
          />
          <q-route-tab
            name="account"
            :label="t('Header.Account')"
            :to="{ name: 'accountView' }"
            replace
          />
          <q-route-tab
            name="invoices"
            :label="t('Header.Invoices')"
            :to="{ name: 'invoicesView' }"
            exact
            replace
          />
        </q-tabs>

        <!-- Spacer for alignment -->
        <q-space />

        <!-- User Avatar & Menu -->
        <q-item
          v-if="isAuthenticated && userName !== ''"
          class="q-pa-none q-pl-sm q-pr-sm"
          clickable
        >
          <q-item-section avatar>
            <q-avatar>
              <img :src="profilePicture">
            </q-avatar>
          </q-item-section>
          <q-item-section>
            {{ userName }}
          </q-item-section>
          <q-menu fit>
            <q-list>
              <q-item clickable @click="logout">
                <q-item-section>
                  {{ t('Header.Logout') }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-item>

        <q-item
          v-else-if="!auth0IsLoading && isAuthenticated"
          class="q-pa-none q-pl-sm q-pr-sm"
          clickable
          @click="logout"
        >
          <q-item-section avatar>
            <q-avatar
              color="grey-8"
              text-color="white"
              icon="person"
            />
          </q-item-section>
          <q-item-section>
            {{ t('Header.Logout') }}
          </q-item-section>
        </q-item>
      </q-toolbar>
    </q-header>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useAuth0 } from '@auth0/auth0-vue'
import { useRouter, useRoute } from 'vue-router'
import { useQuasar } from 'quasar'

// Global
const { t } = useI18n({ useScope: 'global' })
const store = useStore()
const auth0 = useAuth0()
const router = useRouter()
const route = useRoute()
const $q = useQuasar()

const isMobile = computed(() => {
  return $q.platform.is.mobile
})

// User Info
const userName = computed(() => {
  return store.getters['Global/user/getFirstName']
})

const profilePicture = computed(() => {
  return store.getters['Global/user/getProfilePicture']
})

const isAuthenticated = computed(() => {
  return auth0.isAuthenticated.value
})

const auth0IsLoading = computed(() => {
  return auth0.isLoading.value
})

// Plan Info

const currentPlan = computed(() => {
  return store.getters['Global/plans/getCurrentPlan']
})

// Nav Logic
const navTab = ref('dashboard')
const leftDrawerOpen = ref(false)

const toggleLeftDrawer = () => {
  leftDrawerOpen.value = !leftDrawerOpen.value
}

const goTo = (routeName) => {
  router.push({ name: routeName })
}

const isActiveRoute = (routeName) => {
  return route.name === routeName
}

// Logout
const logout = function () {
  auth0.logout({
    logoutParams: {
      returnTo: `${window.origin}/dashboard`
    }
  })
}
</script>

<style lang="scss" scoped>
.navHeader {
  height: 75px;
  line-height: 75px;
}
.headerToolbar {
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
}
.headerLogo {
  width: 150px;
}
.navTabs {
  height: 75px;
}

@media (max-width: 1024px) {
  .headerToolbar {
    width: 100%;
  }
}

/* Custom styles for mobile responsiveness */
.hamburger-btn {
  display: none;
}
.q-header .navTabs {
  margin-left: 20px;
}
.q-toolbar .headerLogo {
  width: 150px;
}
.q-header .q-tabs .q-tab {
  min-width: auto;
  padding: 0 10px;
}

@media (max-width: 767px) {
  .hamburger-btn {
    display: block; /* Show hamburger on mobile */
  }

  .headerLogo {
    margin-left: auto;
    margin-right: auto;
  }

  .q-tabs {
    display: none; /* Hide tabs on mobile */
  }
}
.active {
  background-color: var(--primary-color);
  font-family: InterBold;
}
</style>
