import { createRouter, createWebHistory } from 'vue-router'
import domains from '../Global/composables/domains.js'

let routes = []

domains.forEach((route) => {
  const domainRoutes = require(`../${route}/routes.js`)
  routes = routes.concat(domainRoutes.default)
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  if (typeof to.meta?.title !== 'undefined') {
    const appName = 'Wirecast Billing'
    document.title = `${to.meta.title} | ${appName}`
  }
})

export default router
