<template>
  <div>
    <q-layout
      view="hHh lpr lfr"
    >
      <top-nav v-if="!isEmailConsentPage" />
      <q-page-container
        class="pageContainer"
      >
        <q-page class="page">
          <ts-loader v-if="isLoading" class="q-pa-xl q-mt-md" />
          <router-view
            v-if="!isLoading"
            v-slot="{ Component }"
            class="q-pa-md"
            :class="{
              routerView: !isEmailConsentPage
            }"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </q-page>
      </q-page-container>
      <footer-nav v-if="!isEmailConsentPage" />
    </q-layout>
  </div>
</template>

<script setup>
import TsLoader from '@/Global/components/ts-loader.vue'
import TopNav from '@/Global/components/top-nav.vue'
import FooterNav from '@/Global/components/footer-nav.vue'
import { onMounted, computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { useAuth0 } from '@auth0/auth0-vue'

// Global
const $q = useQuasar()
const store = useStore()
const router = useRouter()
const auth0 = useAuth0()

const isLoading = computed(() => {
  return auth0.isLoading.value || auth0Loading.value || profileLoading.value || subscriptionLoading.value
})

const isEmailConsentPage = computed(() => {
  return router.currentRoute.value.name === 'emailConsent'
})

// Theme Logic

onMounted(() => {
  setTheme()
})

const setTheme = function () {
  const theme = localStorage.getItem('theme')

  if (theme !== null && theme === 'light') {
    $q.dark.set(false)
  } else {
    $q.dark.set(true)
  }
}

// User Logic

const auth0Loading = ref(true)
const profileLoading = ref(true)

watch(() => auth0.isLoading.value, (to, from) => {
  if (from && !to) {
    getUserAuth0()
  }
})

const getUserAuth0 = async function () {
  if (auth0.isAuthenticated.value) {
    auth0Loading.value = true
    store.dispatch('Global/user/getUserAuth0', auth0).then(() => {
      auth0Loading.value = false
      getUserProfile()
      getSubscriptionList()
    })
  } else {
    auth0Loading.value = false
    profileLoading.value = false
    subscriptionLoading.value = false
  }
}

const getUserProfile = function () {
  profileLoading.value = true
  store.dispatch('Global/user/getUserProfile').then(() => {
    profileLoading.value = false
  }).catch((error) => {
    console.log(error)
    profileLoading.value = false
  })
}

// Plans Logic

const subscriptionLoading = ref(true)

const getSubscriptionList = function () {
  subscriptionLoading.value = true
  store.dispatch('Global/plans/getSubscriptionList').then(() => {
    subscriptionLoading.value = false
  }).catch((error) => {
    console.log(error)
    subscriptionLoading.value = false
  })
}
</script>

<style scoped>
.routerView {
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;
}

.pageContainer {
  padding-bottom: 0px !important;
}

.page {
  min-height: calc(100vh - 75px - 425px) !important;
}

@media (max-width: 1024px) {
  .routerView {
    width: 100%;
  }
}
</style>
<style lang="scss" src="./styles/app.scss" />
