import { authGuard } from '@auth0/auth0-vue'
const SelectPlanEntryPoint = () => import('@/CreateAccountPlan/EntryPointSelectPlan.vue')
const PaymentInfoEntryPoint = () => import('@/CreateAccountPlan/EntryPointPaymentInfo.vue')
const PaymentSummaryEntryPoint = () => import('@/CreateAccountPlan/EntryPointPaymentSummary.vue')

const router = [
  {
    path: '/select-plan',
    name: 'selectPlan',
    component: SelectPlanEntryPoint,
    meta: {
      title: 'Select Plan'
    }
  },
  {
    path: '/payment-info',
    name: 'paymentInfo',
    component: PaymentInfoEntryPoint,
    beforeEnter: authGuard,
    meta: {
      title: 'Payment Information'
    }
  },
  {
    path: '/payment-summary',
    name: 'paymentSummary',
    component: PaymentSummaryEntryPoint,
    meta: {
      title: 'Payment Summary'
    },
    beforeEnter: (to, from) => {
      if (from.name === 'paymentInfo') {
        to.meta = {
          completedFlow: true
        }
      } else {
        to.meta = {
          completedFlow: false
        }
      }
      authGuard(to, from)
    }
  }
]

export default router
