import { createStore } from 'vuex'

import domains from '../composables/domains.js'

const modules = {}

domains.forEach((route) => {
  const files = require.context('../../', true, /.*store.*\.js$/)

  files.keys().forEach(key => {
    if (key.includes('index.js')) return
    modules[key.replace(/(\.\/|\.js|\/store)/g, '')] = files(key).default
  })
})

export default createStore({
  modules
})
