import axios from 'axios'
import { ref, reactive, computed } from 'vue'

// Global
const accessToken = ref('')

// Headers
const billingHeaders = computed(() => {
  return {
    Authorization: `Bearer ${accessToken.value}`
  }
})

// Environment URLs
const billingV1Url = process.env.VUE_APP_BILLING_API_V1_URL

// Axios
const axiosGet = ({ base, headers, endpoint, params = null, fullResponse }) => {
  return new Promise((resolve, reject) => {
    let url = `${base}${endpoint}`
    if (params) {
      url += `?${new URLSearchParams(params).toString()}`
    }
    axios.get(url, { headers }).then((response) => {
      if (fullResponse) {
        resolve(response)
      } else {
        resolve(response.data)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

const axiosPost = ({ base, headers, endpoint, params, fullResponse }) => {
  return new Promise((resolve, reject) => {
    const url = `${base}${endpoint}`
    axios.post(url, params, { headers }).then((response) => {
      if (fullResponse) {
        resolve(response)
      } else {
        resolve(response.data)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

const axiosPut = ({ base, headers, endpoint, params, fullResponse }) => {
  return new Promise((resolve, reject) => {
    const url = `${base}${endpoint}`
    axios.put(url, params, { headers }).then((response) => {
      if (fullResponse) {
        resolve(response)
      } else {
        resolve(response.data)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

const axiosDelete = ({ base, headers, endpoint, params = null, fullResponse }) => {
  return new Promise((resolve, reject) => {
    let url = `${base}${endpoint}`
    if (params) {
      url += `?${new URLSearchParams(params).toString()}`
    }

    axios.delete(url, { headers }).then((response) => {
      if (fullResponse) {
        resolve(response)
      } else {
        resolve(response.data)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

// Billing Hub
const billingV1Get = async ({ endpoint, params, fullResponse }) => {
  return axiosGet({
    base: billingV1Url,
    headers: await billingHeaders.value,
    endpoint,
    params,
    fullResponse
  })
}

const billingV1Post = async ({ endpoint, params, fullResponse }) => {
  return axiosPost({
    base: billingV1Url,
    headers: await billingHeaders.value,
    endpoint,
    params,
    fullResponse
  })
}

const billingV1Put = async ({ endpoint, params, fullResponse }) => {
  return axiosPut({
    base: billingV1Url,
    headers: await billingHeaders.value,
    endpoint,
    params,
    fullResponse
  })
}

const billingV1Delete = async ({ endpoint, params, fullResponse }) => {
  return axiosDelete({
    base: billingV1Url,
    headers: await billingHeaders.value,
    endpoint,
    params,
    fullResponse
  })
}

// Composable Object
const useApi = reactive({
  billing: {
    v1: {
      get: billingV1Get,
      post: billingV1Post,
      put: billingV1Put,
      delete: billingV1Delete
    }
  },
  accessToken
})

export { useApi }

export default useApi
