import useApi from '@/Global/composables/api.js'

const api = useApi
const namespaced = true
const state = {
  planId: 0,
  planState: '',
  currentPlan: '',
  basePrice: 0,
  nextInvoicePrice: 0,
  numberOfSeats: 0,
  billingFrequency: 'monthly',
  maskedCardNumber: '',
  cardExpiry: '',
  nextBillingDate: '',
  operatingSystem: null,
  cancellationState: '',
  cancellationDate: ''
}

const getters = {
  getPlanId (state) {
    return state.planId
  },
  getPlanState (state) {
    return state.planState
  },
  getCurrentPlan (state) {
    return state.currentPlan
  },
  getBasePrice (state) {
    return state.basePrice
  },
  getNextInvoicePrice (state) {
    return state.nextInvoicePrice
  },
  getNumberOfSeats (state) {
    return state.numberOfSeats
  },
  getBillingFrequency (state) {
    return state.billingFrequency
  },
  getMaskedCardNumber (state) {
    return state.maskedCardNumber
  },
  getCardExpiry (state) {
    return state.cardExpiry
  },
  getNextBillingDate (state) {
    return state.nextBillingDate
  },
  getOperatingSystem (state) {
    return state.operatingSystem
  },
  getCancellationState (state) {
    return state.cancellationState
  },
  getCancellationDate (state) {
    return state.cancellationDate
  }
}

const actions = {
  getSubscriptionList ({ commit }) {
    return new Promise((resolve, reject) => {
      const payload = {
        endpoint: 'subscription'
      }
      api.billing.v1.get(payload)
        .then((response) => {
          if (response.length > 0) {
            const currentPlan = response[response.length - 1]
            commit('setPlanId', currentPlan.id)
            commit('setBasePrice', currentPlan.basePrice)
            commit('setNextInvoicePrice', currentPlan.nextEstimatedInvoiceAmount)
            commit('setPlanState', currentPlan.subscriptionState)
            commit('setCurrentPlan', currentPlan.products[0].identifier)
            commit('setBillingFrequency', currentPlan.billingFrequency)
            commit('setMaskedCardNumber', currentPlan.maskedCreditCard)
            commit('setCardExpiry', currentPlan.creditCardExpiry)
            commit('setNextBillingDate', currentPlan.nextBillingDate)
            commit('setNumberOfSeats', currentPlan.products[0].quantity)

            if (typeof currentPlan.cancellationState !== 'undefined') {
              commit('setCancellationState', currentPlan.cancellationState)
              commit('setCancellationDate', currentPlan.cancellationDate)
            }
            resolve(true)
          } else {
            const errorObj = new Error('No subscriptions found.')
            const response = { status: 404 }
            errorObj.response = response
            reject(errorObj)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setPlanInformation (state, value) {
    state.planId = value.planId
    state.planState = value.planState
    state.currentPlan = value.currentPlan
    state.basePrice = value.basePrice
    state.nextInvoicePrice = value.nextInvoicePrice
    state.numberOfSeats = value.numberOfSeats
    state.billingFrequency = value.billingFrequency
    state.maskedCardNumber = value.maskedCardNumber
    state.cardExpiry = value.cardExpiry
    state.nextBillingDate = value.nextBillingDate
    state.operatingSystem = value.operatingSystem
    state.cancellationDate = value.cancellationDate
    state.cancellationState = value.cancellationState
  },
  setPlanId (state, value) {
    state.planId = value
  },
  setPlanState (state, value) {
    state.planState = value
  },
  setCurrentPlan (state, value) {
    state.currentPlan = value
  },
  setBasePrice (state, value) {
    state.basePrice = value
  },
  setNextInvoicePrice (state, value) {
    state.nextInvoicePrice = value
  },
  setNumberOfSeats (state, value) {
    state.numberOfSeats = value
  },
  setBillingFrequency (state, value) {
    state.billingFrequency = value
  },
  setMaskedCardNumber (state, value) {
    state.maskedCardNumber = value
  },
  setCardExpiry (state, value) {
    state.cardExpiry = value
  },
  setNextBillingDate (state, value) {
    state.nextBillingDate = value
  },
  setOperatingSystem (state, value) {
    state.operatingSystem = value
  },
  setCancellationDate (state, value) {
    state.cancellationDate = value
  },
  setCancellationState (state, value) {
    state.cancellationState = value
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced
}
