import { authGuard } from '@auth0/auth0-vue'

const Error404Page = () => import('@/Global/views/404Page.vue')
const AuthenticateView = () => import('@/Global/views/AuthenticateView.vue')
const EmailConsentView = () => import('@/Global/views/EmailConsentView.vue')

const router = [
  {
    path: '/',
    name: 'root',
    redirect: to => {
      return '/select-plan'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error404',
    component: Error404Page,
    meta: {
      title: 'Error'
    }
  },
  {
    path: '/authenticate',
    name: 'authenticate',
    component: AuthenticateView,
    meta: {
      title: 'Authenticate'
    }
  },
  {
    path: '/email-consent',
    name: 'emailConsent',
    component: EmailConsentView,
    meta: {
      title: 'Email Consent'
    }
  }
]

export default router
