import { authGuard } from '@auth0/auth0-vue'

const DashboardEntryPoint = () => import('@/Dashboard/EntryPoint.vue')
const DashboardView = () => import('@/Dashboard/views/DashboardView.vue')
const AccountView = () => import('@/Dashboard/views/AccountView.vue')
const InvoicesView = () => import('@/Dashboard/views/InvoicesView.vue')
const InformationAndAccessView = () => import('@/Dashboard/views/InformationAndAccessView.vue')
const PersonalDataView = () => import('@/Dashboard/views/PersonalDataView.vue')

const router = [
  {
    path: '/dashboard',
    name: 'DashboardEntryPoint',
    component: DashboardEntryPoint,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'dashboardView',
        component: DashboardView,
        meta: {
          title: 'Dashboard'
        }
      },
      {
        path: '/account',
        name: 'accountView',
        component: AccountView,
        redirect: to => {
          return '/account/information-and-access'
        },
        children: [
          {
            path: 'information-and-access',
            name: 'informationAndAccess',
            component: InformationAndAccessView,
            meta: {
              title: 'Information and Access'
            }
          },
          {
            path: 'personal-data',
            name: 'personalData',
            component: PersonalDataView,
            meta: {
              title: 'Personal Data'
            }
          }
        ]
      },
      {
        path: '/invoices',
        name: 'invoicesView',
        component: InvoicesView,
        meta: {
          title: 'Invoices'
        }
      }
    ]
  }
]

export default router
